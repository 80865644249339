// Career Styling
.career-content {
    color: white;
    min-height: 700px;
    z-index: 2 !important;
}

.form-head {
    padding-bottom: 30px;

    h2 {
        font-weight: bolder;
    }
}

// Career Styling Ends


// Form Styling
.form-field {
    border-radius: 0px !important;
    padding: 15px 20px !important;
    border: 3px solid rgb(255, 62, 0) !important;
}

.form-btn {
    width: 100%;
    padding: 15px 0px !important;
    border-radius: 0px !important;
    border: 3px solid rgba(255, 255, 255, 0) !important;
    font-weight: bold;
}

.form-btn:hover {
    background-color: rgba(161, 14, 14, 0) !important;
    border: 3px solid rgb(255, 62, 0) !important;


}

.submit-message {
    font-size: 20px;
    color: rgb(0, 255, 0);
    font-weight: bold;
}

// Form Styling Ends