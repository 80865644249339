/* --------- */
/* About */
/* --------- */

.about-section {
    position: relative !important;
    padding-top: 150px !important;
    padding-bottom: 30px !important;
    background-image: var(--section-background-color) !important;
    color: white !important;
}

.tech-icons {
    font-size: 4.5em;
    margin: 15px !important;
    padding: 10px !important;
    opacity: 0.93 !important;
    border: 1.7px solid rgba(230, 167, 137, 0.637) !important;
    vertical-align: middle !important;
    text-align: center !important;
    border-radius: 5px !important;
    display: table !important;
    box-shadow: 4px 5px 4px 3px rgba(168, 56, 4, 0.137) !important;
    overflow: hidden !important;
    transition: all 0.4s ease 0s !important;
}

@media (max-width: 767px) {
    .tech-icons {
        margin: 10px !important;
    }
}

.tech-icons:hover {
    transform: scale(1.05) !important;
    overflow: hidden !important;
    border: 2.2px solid rgba(230, 157, 115, 0.883) !important;
}

// About End  


// Motive

.motive {
    color: white !important;
    font-size: 4.5em;
    margin: 15px !important;
    padding: 10px !important;
    opacity: 0.93 !important;
    border: 1.7px solid rgba(230, 167, 137, 0.637) !important;
    vertical-align: middle !important;
    text-align: center !important;
    display: table !important;
    box-shadow: 4px 5px 4px 3px rgba(168, 56, 4, 0.137) !important;
    overflow: hidden !important;
    transition: all 0.4s ease 0s !important;
}

@media (max-width: 767px) {
    .motive {
        margin: 10px !important;
    }
}

.motive:hover {
    transform: scale(1.05) !important;
    overflow: hidden !important;
    border: 2.2px solid rgba(230, 157, 115, 0.883) !important;
}

.motive-card {
    h2 {
        padding-bottom: 10px;
    }

    p {
        color: #cecece;
        font-size: 22px;
        text-align: justify !important;
        padding: 10px;
    }

}

// Motive End

// Services
.services {
    color: white !important;
    font-size: 4.5em;
    padding: 10px !important;
    opacity: 0.93 !important;
    border: 1.7px solid rgba(230, 167, 137, 0.637) !important;
    vertical-align: middle !important;
    text-align: center !important;
    display: table !important;
    box-shadow: 4px 5px 4px 3px rgba(168, 56, 4, 0.137) !important;
    overflow: hidden !important;
    transition: all 0.4s ease 0s !important;
}

@media (max-width: 767px) {
    .services {
        margin: 10px !important;
    }
}

.services:hover {
    transform: scale(1.05) !important;
    overflow: hidden !important;
    border: 2.2px solid rgba(230, 157, 115, 0.883) !important;
    background-color: #181a27;
    z-index: 2;
}

.services-card {
    h2 {
        padding-bottom: 10px;
    }

    p {
        color: #cecece;
        font-size: 22px;
        text-align: justify !important;
        padding: 10px;
    }

}



.tech-icon-images {
    padding: 20px !important;
    line-height: 1.6 !important;
}

.quote-card-view {
    border: none !important;
    color: white !important;
    background-color: transparent !important;
}

.about-activity {
    list-style: none !important;
    text-align: left !important;
    padding-left: 1px !important;
}

@media (max-width: 767px) {
    .about-img {
        padding-top: 0 !important;
    }
}

// Services End