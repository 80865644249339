// Form Styling
.career-form {
    padding: 20px 0px;

    h2 {
        padding: 60px 0px;
        font-weight: bold;
    }
}

.form-field {
    border-radius: 0px !important;
    padding: 15px 20px !important;
    border: 3px solid rgb(255, 62, 0) !important;
}

.form-btn {
    width: 100%;
    padding: 15px 0px !important;
    margin: 50px 0px;
    border-radius: 0px !important;
    border: 3px solid rgba(255, 255, 255, 0) !important;
}

.form-btn:hover {
    background-color: rgba(161, 14, 14, 0) !important;
    border: 3px solid rgb(255, 62, 0) !important;

}

.submit-message {
    font-size: 20px;
    color: rgb(0, 255, 0);
    font-weight: bold;
}

// Form Styling Ends