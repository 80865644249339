* {
  @media (max-width: 767px) {
    p {
      font-size: 18px !important;
      text-align: left !important;
    }

    h1 {
      font-size: 30px !important;
    }

    h2 {
      font-size: 27px !important;
    }

    h3 {
      font-size: 25px !important;
    }

    h4 {
      font-size: 23px !important;
    }

    h5 {
      font-size: 21px !important;
    }

    h6 {
      font-size: 19px !important;
    }

  }
}

html {
  --section-background-color: linear-gradient(to bottom left,
      rgba(17, 16, 16, 0.582),
      rgba(12, 8, 24, 0.904));

  --image-gradient: linear-gradient(to bottom left,
      rgba(17, 16, 16, 0.678),
      rgba(12, 10, 22, 0.863));

  --imp-text-color: rgb(255, 62, 0);
}

.orange {
  color: var(--imp-text-color) !important;
}

button:focus {
  box-shadow: none !important;
}

/* --------- */
/*  Preloader */
/* --------- */
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: #0c0513;
  background-image: url(./Assets/pre.svg);
  background-repeat: no-repeat;
  background-position: center;
}

#preloader-none {
  opacity: 0;
}

#no-scroll {
  overflow: hidden;
  height: 100vh;
}

/* --------- */
/*Scrollbar   */
/* --------- */

::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #2d1950;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(255, 62, 0);
  border-radius: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(255, 62, 0);
  border-radius: 12px;
}

/* --------- */
/* Navbar Section  */
/* --------- */
.sticky {
  background-color: #1b1a2ea9 !important;
  transition: all 0.3s ease-out 0s !important;
  box-shadow: 0px 10px 10px 0px rgba(9, 5, 29, 0.171) !important;
  backdrop-filter: blur(15px) !important;
}

.navbar {
  position: fixed !important;
  transition: all 0.3s ease-out 0s !important;
  padding: 0.3rem 2rem !important;
  font-size: 1.2rem !important;
}

.navbar-toggler {
  position: relative !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.navbar-toggler span {
  display: block !important;
  background-color: rgb(255, 62, 0) !important;
  height: 4px !important;
  width: 27px !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  transform: rotate(0deg) !important;
  left: 0 !important;
  opacity: 1 !important;
}

.navbar-toggler:focus,
.navbar-toggler:active {
  outline: 0 !important;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
  transition: transform 0.35s ease-in-out !important;
  transition: transform 0.35s ease-in-out !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(135deg) !important;
  opacity: 0.9 !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
  height: 12px !important;
  visibility: hidden !important;
  background-color: transparent !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(-135deg) !important;
  opacity: 0.9 !important;
}

@media (max-width: 767px) {
  .navbar {
    padding: 1rem 2rem !important;
    font-size: 1.4rem !important;
    background-color: #181a27 !important;
  }

  .navbar-nav .nav-item a::after {
    display: none !important;
  }
}

.navbar-brand {
  color: rgb(250, 250, 250) !important;
}


@media (min-width: 767px) {
  .nav-container {
    padding: 25px 0px;
  }
}

div.logo {
  align-items: center;

  .tagline {
    color: white;
    padding: 0px;
    font-size: 18px;

    p {
      margin: 0px 10px;
    }

  }
}


.navbar-brand {
  position: relative;

  .tagline {
    position: absolute;
    left: 5px;
    top: 50px;
    font-family: "Barlow Condensed", sans-serif;
    font-weight: 300;
    font-style: normal;

    .Typewriter__wrapper {
      font-size: 1em !important;
      color: rgb(255, 62, 0) !important;
      font-weight: 300 !important;
    }

    .Typewriter__cursor {
      font-size: 1em !important;
      color: rgb(255, 62, 0) !important;
    }

    @media (max-width: 767px) {
      .Typewriter__wrapper {
        font-size: 0.8em !important;
        font-weight: 300 !important;
        position: absolute !important;
      }

      .Typewriter__cursor {
        display: none !important;
      }
    }
  }
}

.navbar-nav .nav-link {
  color: white !important;
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.nav-link {
  padding: 0.8rem 1rem !important;
}

@media (max-width: 767px) {
  .nav-link {
    padding: 0.7rem 1rem !important;
  }
}

.navbar-nav .nav-item {
  position: relative;
  margin-left: 20px;
}

.navbar-nav .nav-item a {
  font-weight: 400;
  transition: all 0.3s ease-out 0s;
  position: relative;
  z-index: 1;
}

.navbar-nav .nav-item a::after {
  content: "";
  position: relative;
  display: block;
  height: 5px;
  width: 0;
  border-radius: 16px;
  background: rgb(255, 62, 0);
  bottom: 1px;
  left: 0;
  z-index: -1;
  transition: all 0.3s ease-out 0s;
}

.navbar-nav .nav-item a:hover::after {
  width: 100%;
}


/* --------- */
/* Footer */
/* --------- */
.footer {
  background-color: rgb(10, 4, 22);
  bottom: 0 !important;
  padding-top: 10px !important;
  padding-bottom: 8px !important;
}

.footer-copywright {
  text-align: center !important;
}

.footer-body {
  z-index: 1;
  text-align: center !important;
}

@media (max-width: 767px) {
  .footer-copywright {
    text-align: center !important;
  }

  .footer-body {
    text-align: center !important;
  }
}

.footer h3 {
  font-size: 1em;
  color: white !important;
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
}

.footer-icons {
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
  padding: 0 !important;
}

.blockquote-footer {
  color: rgb(255, 62, 0) !important;
}

// Form Styling
.form-field {
  border-radius: 0px !important;
  padding: 15px 20px !important;
  border: 3px solid rgb(255, 62, 0) !important;
}

.form-btn {
  width: 100%;
  padding: 15px 0px !important;
  border-radius: 0px !important;
  border: 3px solid white !important;
}

.form-btn:hover {
  background-color: rgba(161, 14, 14, 0) !important;
  border: 3px solid rgb(255, 62, 0) !important;

}

.submit-message {
  font-size: 20px;
  color: rgb(0, 255, 0);
  font-weight: bold;
}

// Form Styling Ends






.card-img-top {
  padding: 20px !important;
  opacity: 0.8 !important;
  border-radius: 10px !important;
}

.blog-img {
  padding: 0px !important;
  opacity: 0.8 !important;
  border-radius: 0px !important;
}

.btn-primary {
  color: #fff !important;
  background-color: rgb(255, 62, 0) !important;
  border-color: rgb(255, 62, 0) !important;
}

.btn-primary:hover {
  color: #fff !important;
  background-color: rgb(255, 62, 0) !important;
  border-color: rgb(255, 62, 0) !important;
}

.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

.project-heading {
  color: white !important;
  font-size: 2.3em !important;
  font-weight: 500 !important;
  padding-top: 10px !important;
}

/* --------- */
/* Resume */
/* --------- */

// .resume-section {
//   position: relative !important;
//   padding-top: 110px !important;
//   padding-bottom: 30px !important;
//   background-image: var(--section-background-color) !important;
//   color: white !important;
// }

// .resume {
//   padding-top: 50px;
//   padding-bottom: 50px;
//   justify-content: center;
// }

// .resume-left {
//   padding-right: 80px !important;
// }

// .resume-right {
//   padding-left: 80px !important;
// }

// @media (max-width: 767px) {
//   .resume-left {
//     padding-right: 15px !important;
//     padding-left: 15px !important;
//   }

//   .resume-right {
//     padding-right: 15px !important;
//     padding-left: 15px !important;
//   }
// }

// .resume .resume-title {
//   font-size: 2em;
//   font-weight: 700;
//   padding-top: 30px;
//   padding-bottom: 30px;
// }

// .resume .resume-item {
//   padding: 0 0 10px 25px;
//   margin-top: -2px;
//   border-left: 2px solid rgb(255, 62, 0);
//   position: relative;
// }

// .resume .resume-item .resume-title {
//   line-height: 18px;
//   font-size: 0.9em;
//   background: #794d345d;
//   padding: 8px 15px;
//   display: inline-block;
//   font-weight: 600;
//   margin-bottom: 10px;
// }

// .resume .resume-item ul {
//   padding-left: 20px;
//   text-align: justify;
// }

// .resume .resume-item ul li {
//   padding-bottom: 10px;
//   list-style: none;
// }

// .resume .resume-item:last-child {
//   padding-bottom: 0;
// }

// .resume .resume-item::before {
//   content: "";
//   position: absolute;
//   width: 16px;
//   height: 16px;
//   border-radius: 50px;
//   left: -9px;
//   top: 0;
//   background: #fff;
//   border: 2px solid rgb(255, 62, 0);
// }

// .like-item {
//   padding-top: 10px !important;
//   font-size: 1.1em !important;
//   font-family: sans-serif !important;
// }

// .like-btn {
//   background-color: #ce754c5e !important;
//   border-color: #ce754c5e !important;
//   padding: 0.25rem 0.98rem !important;
//   border-radius: 5px !important;
//   line-height: 1.4 !important;
//   transition: 0.3s ease !important;
// }

// .like-btn:hover {
//   transform: translateY(-2px) !important;
//   background-color: #d3774d86 !important;
//   border-color: #d3774d86 !important;
// }

// .animate-like {
//   animation-name: likeAnimation;
//   animation-fill-mode: forwards;
//   animation-duration: 0.85s;
// }

// @keyframes likeAnimation {
//   0% {
//     transform: scale(1.5);
//   }

//   100% {
//     transform: scale(1);
//   }
// }

.menu-btn {
  font-size: 1.1em !important;
  padding-top: 0px !important;
}

.menu-btn-inner {
  //line-height: 1.4em !important;
  background-color: #d3774d00 !important;
  //padding: 0.30rem 1.1rem !important;
  vertical-align: middle !important;
  text-align: center !important;
  padding-top: 8px !important;
  border-radius: 0px !important;

  span {
    font-size: 18px;
    font-weight: bold;
  }
}

.menu-btn-inner:hover {
  transform: translateY(-2px) !important;
  background-color: rgb(255, 62, 0) !important;
  border-color: #d3774d86 !important;
}

.menu-btn-inner::after {
  display: none !important;
}