/* --------- */
/* Projects */
/* --------- */
.project-section {
    position: relative !important;
    padding-top: 150px !important;
    padding-bottom: 30px !important;
    font-size: 18px;
    background-image: var(--section-background-color) !important;
}

.project-card {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
    padding-left: 25px !important;
    padding-right: 25px !important;
    height: auto !important;
}

.project-card-view {
    box-shadow: 0 4px 5px 3px rgba(119, 53, 136, 0.459) !important;
    color: white !important;
    background-color: transparent !important;
    opacity: 0.9 !important;
    transition: all 0.5s ease 0s !important;
    height: 100% !important;
}

.project-card-view:hover {
    transform: scale(1.1) !important;
    overflow: hidden !important;
    box-shadow: 0 4px 4px 5px rgba(144, 98, 72, 0.561) !important;
}