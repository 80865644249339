/* --------- */
/* Home section */
/* --------- */
.wave {
    animation-name: wave-animation;
    /* Refers to the name of your @keyframes element below */
    animation-duration: 2.1s;
    /* Change to speed up or slow down */
    animation-iteration-count: infinite;
    /* Never stop waving :) */
    transform-origin: 70% 70%;
    /* Pivot around the bottom-left palm */
    display: inline-block;
}

@keyframes wave-animation {
    0% {
        transform: rotate(0deg);
    }

    10% {
        transform: rotate(14deg);
    }

    /* The following five values can be played with to make the waving more or less extreme */
    20% {
        transform: rotate(-8deg);
    }

    30% {
        transform: rotate(14deg);
    }

    40% {
        transform: rotate(-4deg);
    }

    50% {
        transform: rotate(10deg);
    }

    60% {
        transform: rotate(0deg);
    }

    /* Reset for the last half to pause */
    100% {
        transform: rotate(0deg);
    }
}

#tsparticles {
    position: fixed !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    width: 100%;
    height: 100%;
    z-index: -2 !important;
}

.home-header {
    padding-top: 80px !important;
}

.home-section {
    position: relative;
    z-index: 2;
    background-image: var(--image-gradient), url(/Assets/home-bg.jpg);
    background-position: top center;
    background-repeat: no-repeat;
    padding-bottom: 30px !important;
    padding-top: 30px !important;
    z-index: 1 !important;
}

.home-content {
    padding: 9rem 0 2rem !important;
    color: whitesmoke;
    text-align: left;
}

.heading {
    font-size: 2.4em;
    padding-left: 50px !important;
}

.heading-name {
    font-size: 2.5em;
    padding-left: 45px !important;
}

.main-name {
    color: rgb(255, 62, 0);
}

.Typewriter__wrapper {
    font-size: 2.2em !important;
    color: rgb(255, 62, 0) !important;
    font-weight: 600 !important;
}

.Typewriter__cursor {
    font-size: 2.4em !important;
    color: rgb(255, 62, 0) !important;
}

@media (max-width: 767px) {
    .Typewriter__wrapper {
        font-size: 1.4em !important;
        font-weight: 500 !important;
        // position: absolute !important;
    }

    .Typewriter__cursor {
        display: none !important;
    }
}

.myAvtar {
    justify-content: center !important;
    padding-top: 9em !important;
}

@media (max-width: 767px) {
    .myAvtar {
        padding-top: 2em !important;
        padding-bottom: 2em !important;
    }
}

.home-about-section {
    position: relative;
    padding-bottom: 70px !important;
    padding-top: 70px !important;
    z-index: 2 !important;
}

.home-about-description {
    color: white !important;
    padding-top: 100px !important;
    padding-bottom: 20px !important;
    text-align: center;
}

.home-about-body {
    padding-top: 50px;
    font-size: 2em;
    text-align: justify;

}

.home-about-social {
    text-align: center !important;
    padding-top: 25px;
    color: white !important;
}

.home-about-social-links {
    justify-content: center !important;
    padding-top: 15px !important;
    display: inline-block !important;
    position: relative !important;
    padding-inline-start: 0 !important;
}

.home-social-icons {
    position: relative !important;
    display: inline-block !important;
    width: 60px !important;
    height: 60px !important;
    text-align: center !important;
    font-size: 2.8em;
    line-height: 1.2em !important;
    background: rgba(20, 3, 24, 0.972) !important;
    border-radius: 50% !important;
    transition: 0.5s !important;
}

.home-social-icons::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: rgb(255, 62, 0);
    transition: 0.5s;
    transform: scale(0.9);
    z-index: -1;
}

.home-social-icons:hover::before {
    transform: scale(1.1);
    box-shadow: 0 0 15px rgb(255, 62, 0);
}

.home-social-icons:hover {
    color: rgb(255, 62, 0);
    box-shadow: 0 0 5px rgb(255, 62, 0);
    text-shadow: 0 0 2px rgb(255, 62, 0);
}

.social-icons {
    display: inline-block !important;
    padding-right: 15px;
    padding-left: 15px;
}

.icon-colour {
    color: rgb(255, 62, 0) !important;
}

.home-contact-btn {
    margin: 10px auto !important;
    padding: 12px 0px !important;
    width: 100% !important;
    border-radius: 0px !important;
    font-size: 20px !important;
    font-weight: bolder !important;
    z-index: 2 !important;
}

.home-quote-btn {
    margin: 10px auto !important;
    padding: 12px 0px !important;
    width: 100% !important;
    border-radius: 0px !important;
    font-size: 20px !important;
    font-weight: bolder !important;
    z-index: 2 !important;
}

.home-quote-btn:hover {
    background-color: #0c0513 !important;
}



// Motive

.motive {
    font-size: 4.5em;
    margin: 15px !important;
    padding: 10px !important;
    opacity: 0.93 !important;
    border: 1.7px solid rgba(230, 167, 137, 0.637) !important;
    vertical-align: middle !important;
    text-align: center !important;
    display: table !important;
    box-shadow: 4px 5px 4px 3px rgba(168, 56, 4, 0.137) !important;
    overflow: hidden !important;
    transition: all 0.4s ease 0s !important;
}

@media (max-width: 767px) {
    .motive {
        margin: 10px !important;
    }
}

.motive:hover {
    transform: scale(1.05) !important;
    overflow: hidden !important;
    border: 2.2px solid rgba(230, 157, 115, 0.883) !important;
}

.motive-card {
    h2 {
        padding-bottom: 10px;
    }

    p {
        color: #cecece;
        font-size: 22px;
        text-align: justify !important;
        padding: 10px;
    }

}

// Motive End

// Services
.services {
    font-size: 4.5em;
    padding: 10px !important;
    opacity: 0.93 !important;
    border: 1.7px solid rgba(230, 167, 137, 0.637) !important;
    vertical-align: middle !important;
    text-align: center !important;
    display: table !important;
    box-shadow: 4px 5px 4px 3px rgba(168, 56, 4, 0.137) !important;
    overflow: hidden !important;
    transition: all 0.4s ease 0s !important;
}

@media (max-width: 767px) {
    .services {
        margin: 10px !important;
    }
}

.services:hover {
    transform: scale(1.05) !important;
    overflow: hidden !important;
    border: 2.2px solid rgba(230, 157, 115, 0.883) !important;
    background-color: #181a27;
    z-index: 2 !important;
}

.services-card {
    h2 {
        padding-bottom: 10px;
    }

    p {
        color: #cecece;
        font-size: 22px;
        text-align: justify !important;
        padding: 10px;
    }

}



.tech-icon-images {
    padding: 20px !important;
    line-height: 1.6 !important;
}

.quote-card-view {
    border: none !important;
    color: white !important;
    background-color: transparent !important;
}

.about-activity {
    list-style: none !important;
    text-align: left !important;
    padding-left: 1px !important;
}

@media (max-width: 767px) {
    .about-img {
        padding-top: 0 !important;
    }
}

// Services End